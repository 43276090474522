import {Controller} from 'react-hook-form'
// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
import {Textarea} from 'components/elements/Textarea'
import {AutoComplete} from 'components/elements/AutoComplete'
import {MapPin} from 'components/elements/MapPin'
import {Typography} from 'components/elements/Typography'
// models
import {Address, RegionDeliveryPlanStatusEnum} from 'models'
// hooks
import useText from 'hooks/useText'
import {useHorizontalScroll} from 'hooks/useHorizontalScroll'
// local
import {useData} from './useData'
import {texts} from './texts'

export type Props = {
  show: boolean
  handleClose: () => void
  onSuccess: () => void
  customerId: string
}

const CreateAddressModal = ({show, handleClose, onSuccess, customerId}: Props) => {
  const data = useData({handleClose, onSuccess, show, customerId})
  const {TX} = useText()
  const scrollHorizontalRef = useHorizontalScroll()

  return (
    <Modal
      id='create-customer-address'
      show={show}
      handleClose={handleClose}
      title={data.TX(texts.add_address)}
      children={
        <>
          <form onSubmit={data.onSubmit}>
            <div
              style={
                !!data.addressOnMap.getAddress()
                  ? {
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  : {}
              }
            >
              <MapPin
                coordinates={data.coordinates}
                setCoordinates={data.setCoordinates}
                setAddress={(value: Address) => data.setAddressOnMap(value)}
                clean={!show}
              />
            </div>
            {!!data.addressOnMap.getAddress() && (
              <>
                <div className='row my-5'>
                  <div className='col-sm'>
                    <AutoComplete
                      loading={data.isLoadingProvince}
                      options={data.provinces.map((option: any) => ({
                        value: option.props.id,
                        label: option.props.label,
                      }))}
                      label={TX(texts.province)}
                      value={data.provinceId}
                      onChange={(option: {value: string; label: string} | any) => {
                        data.setFormValue('provinceId', option ? option.value : '', {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                        data.setFormValue('cityId', '', {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                        data.setFormValue('districtId', '')
                        data.setDistrictsCount(0)
                        data.setRegionCount(0)
                      }}
                    />
                  </div>
                  <div className='col-sm'>
                    <AutoComplete
                      loading={data.isLoadingCities}
                      disabled={!data.provinceId}
                      options={data.cities.map((option: any) => ({
                        value: option.props.id,
                        label: option.props.label,
                        districtsCount: option.props.districtsCount,
                      }))}
                      label={TX(texts.city)}
                      value={data.cityId}
                      onChange={(
                        option: {value: string; label: string; districtsCount: number} | any
                      ) => {
                        data.setFormValue('cityId', option ? option.value : '', {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                        data.setFormValue('districtId', '')
                        data.setDistrictsCount(option ? option.districtsCount : 0)
                      }}
                      error={!data.districtsCount}
                      errorText={
                        !!data.addressOnMap.getCityId() ? TX(texts.out_of_service_range) : ''
                      }
                    />
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-sm'>
                    <div className='col-sm'>
                      <Controller
                        name='address'
                        control={data.control}
                        render={({field}) => (
                          <Textfield
                            {...field}
                            label={TX(texts.address_title)}
                            type='text'
                            autoComplete='off'
                            error={!!data.errors.address}
                            touched={!!data.errors.address?.message}
                            errorText={data.errors.address?.message}
                            disabled={data.isLoading || !data.addressOnMap.getAddress()}
                            name='address'
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-sm'>
                    <Controller
                      name='name'
                      control={data.control}
                      render={({field}) => (
                        <Textfield
                          {...field}
                          label={TX(texts.address_label)}
                          id='addressName'
                          name='name'
                          type='text'
                          error={!!data.errors.name}
                          touched={!!data.errors.name?.message}
                          errorText={data.errors.name?.message}
                          disabled={data.isLoading}
                        />
                      )}
                    />
                  </div>
                  <div className='col-sm'>
                    <Controller
                      name='streetTwo'
                      control={data.control}
                      render={({field}) => (
                        <Textfield
                          {...field}
                          label={TX(texts.address_description)}
                          type='text'
                          autoComplete='off'
                          error={!!data.errors.streetTwo}
                          touched={!!data.errors.streetTwo?.message}
                          errorText={data.errors.streetTwo?.message}
                          disabled={data.isLoading}
                          name='streetTwo'
                        />
                      )}
                    />
                  </div>
                </div>

                {data.addressOnMap.getRegion().getRegionDeliveryPlan().length > 0 && (
                  <>
                    <Typography type='h5' className='mt-10'>
                      {TX(texts.delivery_plan)}
                    </Typography>
                    <div className='card' style={{backgroundColor: '#FFF3E0'}}>
                      <div
                        className='p-5 mb-xl-10 d-flex flex-row justify-start align-items-center gap-5 overflow-scroll'
                        ref={scrollHorizontalRef}
                      >
                        {data.addressOnMap
                          .getRegion()
                          .getRegionDeliveryPlan()
                          .filter(
                            (regionDeliveryPlan) =>
                              regionDeliveryPlan.getStatus() ===
                              RegionDeliveryPlanStatusEnum.activate
                          )
                          .map((item) => (
                            <div
                              style={{backgroundColor: '#bbf0a4'}}
                              className='flex-shrink-0 border border-gray-800 p-5 rounded d-flex flex-row gap-5 flex-wrap align-items-start'
                            >
                              <div key={item.getId()} className='p-1 '>
                                {item.getDeliveryPlan().getDisplayDayOfWeek()} -{' '}
                                {TX(texts.count_capacity, [
                                  item.getDeliveryPlan().getDisplayShift(),
                                ])}{' '}
                                {item.getCourierCapacity()}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}
                <div className='row mt-5'>
                  <div className='col-sm'>
                    <Controller
                      name='description'
                      control={data.control}
                      render={({field}) => (
                        <Textarea
                          {...field}
                          id='description'
                          name='description'
                          type='text'
                          label={TX(texts.description)}
                          className='form-control form-control-lg form-control-solid h-25'
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
            <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
              <Button
                text={TX(texts.back)}
                className='main-red-background btn btn-lg background-inherit justify-content-center'
                onClick={() => {
                  handleClose()
                  data.reset()
                }}
                type='button'
              />

              <Button
                text={TX(texts.submit)}
                className='btn btn-lg btn-primary main-gradient-background'
                isLoading={data.isLoading}
                disabled={!data.isValid}
              />
            </div>
          </form>
        </>
      }
    />
  )
}

export {CreateAddressModal}
