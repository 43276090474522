import {IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString} from 'class-validator'
import {ProductStatusEnum} from 'enums'

export class UpdateProductRequestDto {
  @IsString({message: 'نام محصول الزامی است'})
  @IsNotEmpty({message: 'نام محصول الزامی است'})
  name: string

  @IsOptional()
  @IsString()
  description?: string

  @IsString({message: 'نوع الزامی است'})
  @IsNotEmpty({message: 'نوع الزامی است'})
  productTypeId: string

  @IsString({message: 'واحد الزامی است'})
  @IsNotEmpty({message: 'واحد الزامی است'})
  productUnitId: string

  @IsNumber()
  @IsOptional()
  weightInKilogram?: number

  @IsString()
  @IsOptional()
  photoId?: string

  @IsEnum(ProductStatusEnum)
  @IsOptional()
  status?: ProductStatusEnum

  @IsNumber()
  @IsOptional()
  percentagePrice?: number

  @IsNumber()
  @IsOptional()
  customPrice?: number

  @IsBoolean()
  @IsOptional()
  useCustomPrice?: boolean

  constructor() {
    this.name = ''
    this.productTypeId = ''
    this.productUnitId = ''
    this.weightInKilogram = 0
    this.status = ProductStatusEnum.activated
    this.description = ''
    this.percentagePrice = 0
    this.photoId = ''
    this.customPrice = 0
    this.useCustomPrice = false
  }
}
