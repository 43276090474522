import {Controller} from 'react-hook-form'
import {Box} from '@mui/material'
// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
import UploadImagesChunk from 'components/elements/UploadImagesChunk'
import {Typography} from 'components/elements/Typography'
import {Counter} from 'components/elements/Counter'
import {Checkbox} from 'components/elements/Checkbox'
import {Textarea} from 'components/elements/Textarea'
import {AutoComplete} from 'components/elements/AutoComplete'
// models
import {ProductType, ProductUnit, displayProductStatusEnum} from 'models'
// enums
import {ProductStatusEnum, UnitTypeEnum} from 'enums'
// local
import {useData} from './useData'
import {texts} from './texts'
import {useStyles} from './styles'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateProductModal = ({show, handleClose}: Props) => {
  const {
    onSubmit,
    control,
    watch,
    setFormValue,
    isSubmitting,
    errors,
    isValid,
    loading,
    productTypes,
    TX,
    photos,
    setPhotos,
    isSubmitted,
    onSubmitFinish,
    productUnits,
  } = useData(show, handleClose)
  const {classes} = useStyles()

  return (
    <Modal
      id='create-product-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.product_modal_btn)}
      children={
        <form onSubmit={onSubmit} encType='multipart/form-data'>
          <div className='stepper stepper-pills stepper-column' id='kt_modal_create_app_stepper'>
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <div className='d-flex flex-row justify-content-start align-items-start gap-5'>
                <div className='d-flex flex-column justify-content-start align-items-center w-50'>
                  <Controller
                    name='name'
                    control={control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        label={TX(texts.product_name_form)}
                        type='text'
                        name='name'
                        autoComplete='off'
                        error={!!errors.name}
                        touched={!!errors.name?.message}
                        errorText={errors.name?.message}
                        disabled={loading}
                      />
                    )}
                  />
                  <div className='mt-2 w-100'>
                    <Controller
                      name='productUnitId'
                      control={control}
                      render={({field}) => (
                        <AutoComplete
                          {...field}
                          disabled={loading}
                          options={productUnits?.map((option: ProductUnit) => ({
                            value: option.getId(),
                            label: option.getFaName(),
                          }))}
                          label={TX(texts.unit)}
                          value={watch().productUnitId}
                          onChange={(option: {value: string; label: string} | any) =>
                            setFormValue(`productUnitId`, option ? option.value : '', {
                              shouldValidate: true,
                              shouldDirty: true,
                            })
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-start align-items-start w-50'>
                  <div className='me-5'>
                    <Typography type='h4' className='d-block fw-bold fs-5'>
                      {TX(texts.product_image_title)}
                    </Typography>

                    <UploadImagesChunk
                      disabled={loading}
                      photos={photos}
                      setPhotos={setPhotos}
                      isSubmitted={isSubmitted}
                      onSubmitFinish={onSubmitFinish}
                      aspect={1}
                      width='125px'
                      height='125px'
                    />
                  </div>
                  <Box className={classes.useCustomPriceWrapper}>
                    <Controller
                      name='useCustomPrice'
                      control={control}
                      render={({field}) => (
                        <Checkbox
                          {...field}
                          label={TX(texts.use_custom_price)}
                          name={'useCustomPrice'}
                          disabled={loading}
                        />
                      )}
                    />
                    <Controller
                      name='customPrice'
                      control={control}
                      render={({field}) => (
                        <Counter
                          {...field}
                          className={`form-control form-control-lg form-control-solid required`}
                          name='customPrice'
                          autoComplete='off'
                          label={TX(texts.custom_price)}
                          disabled={loading || !watch().useCustomPrice}
                        />
                      )}
                    />
                  </Box>
                </div>
              </div>
              <div className='d-flex flex-row  justify-content-start align-items-start gap-5'>
                <div className='d-flex justify-content-start align-items-center w-100'>
                  <Controller
                    name='productTypeId'
                    control={control}
                    render={({field}) => (
                      <AutoComplete
                        {...field}
                        disabled={loading}
                        options={productTypes?.map((option: ProductType) => ({
                          value: option.getId(),
                          label: option.getFaName(),
                        }))}
                        label={TX(texts.type)}
                        value={watch().productTypeId}
                        onChange={(option: {value: string; label: string} | any) =>
                          setFormValue(`productTypeId`, option ? option.value : '', {
                            shouldValidate: true,
                            shouldDirty: true,
                          })
                        }
                      />
                    )}
                  />
                </div>
                <div className='d-flex justify-content-start align-items-center w-100'>
                  <Controller
                    name='percentagePrice'
                    control={control}
                    render={({field}) => (
                      <Counter
                        {...field}
                        className={`form-control form-control-lg form-control-solid required`}
                        name='percentagePrice'
                        autoComplete='off'
                        value={watch().useCustomPrice ? 0 : field.value}
                        label={TX(texts.percentage_margin)}
                        disabled={loading || watch().useCustomPrice}
                        helperText={TX(texts.percentage)}
                        isPercentage={true}
                      />
                    )}
                  />
                </div>
              </div>
              {productUnits
                .filter(
                  (unit) =>
                    unit.getName() !== UnitTypeEnum.kilogram && unit.getName() !== UnitTypeEnum.gram
                )
                .some((unit) => unit.getId() === watch().productUnitId) && (
                <div className='d-flex flex-row  justify-content-start align-items-start gap-5'>
                  <div className='d-flex justify-content-start align-items-center w-50'>
                    <Controller
                      name='weightInKilogram'
                      control={control}
                      render={({field}) => (
                        <Counter
                          {...field}
                          className={`form-control form-control-lg form-control-solid required`}
                          name='weightInKilogram'
                          autoComplete='off'
                          label={TX(texts.weight)}
                          disabled={loading}
                          helperText={TX(texts.kilogram)}
                          isPercentage={true}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              <div className='d-flex flex-row  justify-content-start align-items-start gap-5 mt-5'>
                <Controller
                  name='description'
                  control={control}
                  render={({field}) => (
                    <Textarea
                      {...field}
                      name='description'
                      label={TX(texts.description)}
                      className='form-control form-control-lg form-control-solid h-25'
                      autoComplete='off'
                      disabled={loading}
                    />
                  )}
                />
              </div>
              <div className='d-flex flex-row  justify-content-start align-items-start gap-5 mt-5'>
                <div className='d-flex justify-content-start align-items-center w-100'>
                  <Controller
                    name='status'
                    control={control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        label={displayProductStatusEnum(watch().status)}
                        name={'status'}
                        disabled={loading}
                        value={watch().status === ProductStatusEnum.activated}
                        onChange={() =>
                          setFormValue(
                            'status',
                            watch().status === ProductStatusEnum.activated
                              ? ProductStatusEnum.deactivated
                              : ProductStatusEnum.activated
                          )
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                <Button
                  text={TX(texts.create_product_cancel)}
                  className='main-red-background btn btn-lg background-inherit justify-content-center'
                  onClick={handleClose}
                  type='button'
                />

                <Button
                  text={TX(texts.create_product_submit)}
                  isLoading={loading}
                  className='btn btn-lg btn-primary main-gradient-background'
                  type='submit'
                  disabled={
                    isSubmitting || !isValid || photos.filter((photo) => photo.isFull).length === 0
                  }
                />
              </div>
            </div>
          </div>
        </form>
      }
    />
  )
}

export {CreateProductModal}
