import {useEffect, useLayoutEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useSnackbar} from 'notistack'
// constants
import {PACKAGING_ROUTE} from 'constants/routes'
// services
import services, {ServerError} from 'services'
// models
import {
  ContentBox,
  CustomerPacking,
  DeliveryBox,
  DeliveryChain,
  DeliveryChainCustomer,
  Dto,
  Line,
  LineStatusEnum,
  Packing,
} from 'models'
import {DeliveryBoxProductPacking} from 'models/DeliveryBoxProductPacking'
// utils
import {getDateStringForQuery} from 'utils/basic/date'
// hooks
import useText from 'hooks/useText'
import useProtectedLayout from 'hooks/useProtectedLayout'
// context
import {usePackagingContext} from '../context'
// locals
import {texts} from './texts'

export type ContentCheck = {
  contentId: string
  sortingProductId: string
  checked: boolean
}

type PackingStateIds = {
  packingId: string
  packingDeliveryId: string
  packingDeliveryBoxId: string
  packingDelieryBoxProductsIds: {packingDeliveryBoxProductId: string; status: string}[]
}

export const useData = () => {
  const {enqueueSnackbar} = useSnackbar()
  const {setConfig} = useProtectedLayout()
  const {TX} = useText()
  const {
    line,
    setLine,
    date,
    setDate,
    deliveryChain,
    setDeliveryChian,
    customer,
    setCustomer,
    box,
    setBox,
  } = usePackagingContext()

  const initialStateIds = {
    packingId: '',
    packingDeliveryId: '',
    packingDeliveryBoxId: '',
    packingDelieryBoxProductsIds: [],
  }
  const [packingStateIds, setPakcingStateIds] = useState<PackingStateIds>(initialStateIds)
  const [packageCount, setPackageCount] = useState<number>(1)
  const [checkedContents, setCheckedContents] = useState<ContentCheck[]>([])
  const [lines, setLines] = useState<Line[]>([])

  const [deliveryChains, setDeliveryChians] = useState<DeliveryChain[]>([])
  const [customers, setCustomers] = useState<DeliveryChainCustomer[]>([])
  const [boxes, setBoxes] = useState<DeliveryBox[]>([])
  const [boxContents, setBoxContents] = useState<ContentBox[]>([])

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const getLinesQueryFunction = async () =>
    await services.packing.getPackingLines({
      'filters[status]': LineStatusEnum.activated,
    })

  const getDeliveryChainsQueryFunction = () =>
    services.packing.getDeliveryChains({
      'filters[date]': getDateStringForQuery(date),
    })

  const startDeliveryChianPackingQueryFunction = async () =>
    await services.packing.startDeliveryChainPacking(deliveryChain?.getId()!, line?.getId()!)

  const finishDeliveryChainPackingQueryFunction = async () =>
    await services.packing.finishDeliveryChainPacking(packingStateIds.packingId, line?.getId()!)

  const downloadLableForDeliveryChianQueryFunction = async () =>
    await services.packing.downloadLabel(
      {
        deliveryChainId: deliveryChain?.getId()!,
        fileName: TX(texts.service_tag_service_number, [String(deliveryChain?.getServiceNumber())]),
      },
      {}
    )

  const getCustomersQueryFunction = async () =>
    await services.packing.getCustomerForDeliveryChians(deliveryChain?.getId()!, {
      getAll: true,
    })

  const startPackingCustomerDeliveryChainQueryFunction = async () =>
    await services.packing.startDeliveryChainCustomerPacking(
      packingStateIds.packingId,
      customer?.getId()!
    )

  const finishCustomerPackingQueryFunctino = async () =>
    await services.packing.finishDeliveryChianCustomerPacking(packingStateIds.packingDeliveryId)

  const getCustomerDeliveryBoxesQueryFunction = async () =>
    await services.packing.getCustomerDeliveryBoxes(customer?.getId()!, {
      getAll: true,
    })

  const startBoxPackingQueryFunction = async () =>
    await services.packing.startDeliveryBoxPacking(packingStateIds.packingDeliveryId, box?.getId()!)

  const finishBoxPackingQueryFunction = async () =>
    await services.packing.finishDelieryBoxPacking(box?.getPackingDeliveryBoxId()!)

  const getBoxContentsQueryFunction = async () =>
    await services.packing.getDeliveryBoxContents(box?.getId()!, {
      getAll: true,
    })

  const finishPackingContentQueryFunction = async (body: {
    packingDeliveryBoxProductId: string
    sortingProductBoxId?: string
  }) =>
    await services.packing.finishDeliveryBoxProductPacking(
      body.packingDeliveryBoxProductId,
      body.sortingProductBoxId
    )

  const downloadLableForCustomerQueryFunction = async () =>
    await services.packing.downloadLabel(
      {
        deliveryChainId: deliveryChain?.getId()!,
        fileName: TX(texts.box_label, [customer?.getCustomer().getFullName()!]),
      },
      {
        'filters[deliveryId]': customer?.getDelivery().getId(),
      }
    )

  /* ------------------------------------------------------------ */
  /*                           Queries                            */
  /* ------------------------------------------------------------ */

  const {isLoading: loadingLines, isFetching: fetchingLines} = useQuery(
    ['getting-packing-lines'],
    getLinesQueryFunction,
    {
      refetchOnWindowFocus: false,
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        const dto = new Dto(data)
        setLines(dto.getItems().map((line) => new Line(line)))
        setLine(new Line(dto.getItems()[0]))
      },
    }
  )

  const {
    isLoading: loadingDeliveryChians,
    isFetching: fetchingDeliveryChains,
    refetch: refetchDeliveryChian,
  } = useQuery(
    ['get-delivery-chains-for-packing', date.toLocaleDateString()],
    getDeliveryChainsQueryFunction,
    {
      refetchOnWindowFocus: false,
      enabled: !!line,
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {
        const deliveryChains = new Dto(data)
          .getItems()
          .map((deliveryChain) => new DeliveryChain(deliveryChain))
        setDeliveryChians(deliveryChains)
        setDeliveryChian(
          deliveryChains.find(
            (searchingDeliveryChian) => searchingDeliveryChian.getId() === deliveryChain?.getId()
          )
        )
      },
    }
  )

  const {
    isLoading: loadingCustomers,
    isFetching: fetchingCustomers,
    refetch: refetchCustomers,
  } = useQuery(['gettting-customers', deliveryChain?.getId()], getCustomersQueryFunction, {
    refetchOnWindowFocus: false,
    enabled: !!deliveryChain,
    onError: (error: ServerError) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    },

    onSuccess: (data) => {
      const customers = data.map((customer) => new DeliveryChainCustomer(customer))
      setCustomers(customers)
      setCustomer(
        customers.find((searchinigCustomer) => searchinigCustomer.getId() === customer?.getId())
      )
    },
  })

  const {
    isLoading: loadingCustomerBoxes,
    isFetching: fetchingCustomerBoxes,
    refetch: refetchCustomerBoxes,
  } = useQuery(
    ['getting-customer-boxes', customer?.getId()],
    getCustomerDeliveryBoxesQueryFunction,
    {
      refetchOnWindowFocus: false,
      enabled: !!customer,
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {
        const contentBoxes = data.map((box) => new DeliveryBox(box))

        setBoxes(contentBoxes)
        setBox((box) => contentBoxes.find((searchingBox) => searchingBox.getId() === box?.getId()))
      },
    }
  )

  const {
    isLoading: loadingBoxContents,
    isFetching: fetchingBoxContents,
    refetch: refetchBoxContents,
  } = useQuery(['get-box-contents', box?.getId()], getBoxContentsQueryFunction, {
    refetchOnWindowFocus: false,
    enabled: !!box,

    onError: (error: ServerError) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    },

    onSuccess: (data) => {
      setBoxContents(data.map((content) => new ContentBox(content)))
      setPakcingStateIds((prev) => ({
        ...prev,
        packingDelieryBoxProductsIds: data.map((content) => {
          const contentBox = new ContentBox(content)
          return {
            packingDeliveryBoxProductId:
              contentBox.getPackingDeliveryBoxProducts()[0]?.getId() || '',
            status: contentBox.getPackingDeliveryBoxProducts()[0]?.getStatus(),
          }
        }),
      }))
    },
  })

  /* ------------------------------------------------------------ */
  /*                         Mutations                            */
  /* ------------------------------------------------------------ */

  const {isLoading: loadingAddExtraBox, mutate: addExtarBox} = useMutation(
    ['add-extra-box', packageCount],
    () => services.packing.addExtraDeliveryBox(box?.getId()!),
    {
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {
        setPackageCount((prev) => prev + 1)
        enqueueSnackbar(TX(texts.create_package_count_success), {
          variant: 'success',
        })

        enqueueSnackbar(TX(texts.please_print_labels_again_message), {
          variant: 'info',
        })
      },
    }
  )

  const {mutate: startDeliveeryChianPakcking, isLoading: loadingStartDeliveryChainPacking} =
    useMutation(
      ['start-deliverychian-packing', deliveryChain?.getId()],
      startDeliveryChianPackingQueryFunction,
      {
        onError: (error: ServerError) => {
          enqueueSnackbar(error.message, {
            variant: 'error',
          })
        },

        onSuccess: (data) => {
          const packingDeliveryChian = new Packing(data)
          setPakcingStateIds((prev) => ({...prev, packingId: packingDeliveryChian.getId()}))
          refetchDeliveryChian()
        },
      }
    )

  const {
    isLoading: loadingFinishDeliveryChianPacking,
    mutate: finishDeliveryChainPackingMutateFunction,
  } = useMutation(
    ['finish-deliveychain-packing', deliveryChain?.getId()],
    finishDeliveryChainPackingQueryFunction,
    {
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'info',
        })
      },

      onSuccess: (data) => {
        refetchDeliveryChian()
      },
    }
  )

  const {isLoading: loadingDownloadDeliveryChianLabels, mutate: downloadLabelsForDeliveryChian} =
    useMutation(
      ['download-labels', customer?.getId()],
      downloadLableForDeliveryChianQueryFunction,
      {
        onError: (error: ServerError) => {
          enqueueSnackbar(error.message, {
            variant: 'error',
          })
        },

        onSuccess: (data) => {},
      }
    )

  const {
    isLoading: loadingStartCustomerDeliveryChainPacking,
    mutate: startCustomerDeliveryChianPackingQuery,
  } = useMutation(
    ['start-customer-deliverychain-packing', customer?.getId()],
    startPackingCustomerDeliveryChainQueryFunction,
    {
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {
        const customerPacking = new CustomerPacking(data)
        setPakcingStateIds((prev) => ({...prev, packingDeliveryId: customerPacking.getId()}))
        refetchCustomers()
      },
    }
  )

  const {isLoading: loadingFinishCustomerPacking, mutate: finishCustomerPackingMutateFunction} =
    useMutation(
      ['finish-packing-customer', packingStateIds.packingDeliveryId],
      finishCustomerPackingQueryFunctino,
      {
        onError: (error: ServerError) => {
          enqueueSnackbar(error.message, {
            variant: 'error',
          })
        },

        onSuccess: (data) => {
          refetchCustomers()
        },
      }
    )

  const {isLoading: loadingStartBoxPacking, mutate: startBoxPackingQuery} = useMutation(
    ['start-box-packing', box?.getId()],
    startBoxPackingQueryFunction,
    {
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {
        refetchCustomerBoxes()
        refetchBoxContents()
      },
    }
  )

  const {isLoading: laodingFinishBoxPacking, mutate: finishBoxPackingMutateFunction} = useMutation(
    ['finish-packing-box', box?.getId()],
    finishBoxPackingQueryFunction,
    {
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {
        refetchCustomerBoxes()
      },
    }
  )

  const {isLoading: loadingFinishContentPacking, mutate: finishContentpacking} = useMutation(
    ['finish-contentbox-packing'],
    finishPackingContentQueryFunction,
    {
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {
        const deliveyBoxProductPacking = new DeliveryBoxProductPacking(data)
        setPakcingStateIds((prev) => ({
          ...prev,
          packingDelieryBoxProductsIds: prev.packingDelieryBoxProductsIds.map((product) => {
            if (product.packingDeliveryBoxProductId === deliveyBoxProductPacking.getId()) {
              product.status = deliveyBoxProductPacking.getStatus()
            }

            return product
          }),
        }))
      },
    }
  )

  const {isLoading: loadingDownlaodCustomerLabels, mutate: downloadLabelsForCustomer} = useMutation(
    ['download-labels', customer?.getId()],
    downloadLableForCustomerQueryFunction,
    {
      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {},
    }
  )

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const handleAddToCheckedContents = (content: ContentBox, sortingProductId: string) => {
    if (!packingStateIds.packingDeliveryBoxId) {
      enqueueSnackbar(TX(texts.start_packing_box_message), {
        variant: 'info',
      })
      return
    }
    const isExistContent = checkedContents.find(
      (searchingContent) => searchingContent.contentId === content.getId()
    )
    if (isExistContent) {
      setCheckedContents((prev) =>
        prev.map((searchingContent) => {
          if (searchingContent.contentId === content.getId()) {
            searchingContent = {
              ...searchingContent,
              sortingProductId,
            }
          }

          return searchingContent
        })
      )
    } else
      setCheckedContents((prev) => [
        ...prev,
        {
          contentId: content.getId(),
          sortingProductId,
          checked: true,
        },
      ])

    finishContentpacking({
      packingDeliveryBoxProductId: content.getPackingDeliveryBoxProducts()[0].getId(),
      sortingProductBoxId: sortingProductId,
    })
  }

  const handleRemoveCheckedContents = (contentId: string) => {
    setCheckedContents((prev) =>
      prev.filter((searchingContent) => searchingContent.contentId !== contentId)
    )
  }

  const finishDeliveryChainPacking = () => {
    if (customers.some((customer) => customer.getPackingDelivery().getStatus() !== 'finish')) {
      enqueueSnackbar(TX(texts.please_finish_all_customer_box_message), {
        variant: 'info',
      })
      return
    } else finishDeliveryChainPackingMutateFunction()
  }

  const startCustomerDeliveryChianPacking = () => {
    if (!packingStateIds.packingId) {
      enqueueSnackbar(TX(texts.start_packing_chains_message), {
        variant: 'info',
      })
    } else {
      startCustomerDeliveryChianPackingQuery()
    }
  }

  const finishCustomerPacking = () => {
    if (boxes.some((box) => box.getPackingDeliveryBox().getStatus() !== 'finish')) {
      enqueueSnackbar(TX(texts.please_all_packing_boxes_message), {
        variant: 'info',
      })

      return
    } else finishCustomerPackingMutateFunction()
  }

  const startBoxPacking = () => {
    if (!packingStateIds.packingDeliveryId) {
      enqueueSnackbar(TX(texts.start_packing_customer_message), {
        variant: 'info',
      })
    } else {
      startBoxPackingQuery()
    }
  }

  const finishBoxPacking = () => {
    if (
      packingStateIds.packingDelieryBoxProductsIds.some((product) => product.status !== 'finish')
    ) {
      enqueueSnackbar(TX(texts.start_must_be_products_packing_message), {
        variant: 'info',
      })
      return
    } else finishBoxPackingMutateFunction()
  }

  const handleChangeDeliveryDate = (value: Date | null) => {
    if (!value) return

    setDate(value)
    // data.handleChangeUrl({
    //   ...data.query,
    //   'filters[deliveryDate]': value,
    // })
  }

  /* ------------------------------------------------------------ */
  /*                         LifeCycles                           */
  /* ------------------------------------------------------------ */

  useLayoutEffect(() => {
    setConfig({
      pageName: [TX(texts.packing)],
      pageDescription: [],
      toolbarType: 'none',
      breadcrumbsItems: [
        {
          title: TX(texts.packing),
          path: PACKAGING_ROUTE,
          isActive: false,
          isSeparator: false,
        },
      ],
    })
  }, [TX, setConfig])

  useEffect(() => {
    setCheckedContents([])
  }, [box])

  useEffect(() => {
    setDeliveryChian(undefined)
    setCustomer(undefined)
    setBoxContents([])

    setPakcingStateIds(initialStateIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line])

  useEffect(() => {
    setPakcingStateIds((prev) => ({...prev, packingId: deliveryChain?.getPackingId()!}))
  }, [deliveryChain])

  useEffect(() => {
    if (!line) return
    setDeliveryChian(undefined)
    refetchDeliveryChian()
  }, [date, line, refetchDeliveryChian, setDeliveryChian])

  useEffect(() => {
    setPakcingStateIds((prev) => ({...prev, packingDeliveryId: customer?.getPackingDeliveryId()!}))
  }, [customer])

  useEffect(() => {
    if (!deliveryChain) return
    setCustomer(undefined)
    setBox(undefined)
    refetchCustomers()
    setBoxes([])
    setBoxContents([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryChain, date])

  useEffect(() => {
    if (!customer) return
    setBox(undefined)
    refetchCustomerBoxes()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, date])

  useEffect(() => {
    setPakcingStateIds((prev) => ({
      ...prev,
      packingDeliveryBoxId: box?.getPackingDeliveryBox().getId()!,
    }))
  }, [box])

  useEffect(() => {
    if (!box) return
    refetchBoxContents()
  }, [box, refetchBoxContents])

  return {
    TX,
    date,

    loadingLines: loadingLines || fetchingLines,
    setLine,
    lines,
    line,

    loadingDeliveryChians: loadingDeliveryChians || fetchingDeliveryChains,
    setDeliveryChian,
    deliveryChains,
    deliveryChain,

    loadingCustomers: loadingCustomers || fetchingCustomers,
    setCustomer,
    customers,
    customer,

    loadingCustomerBoxes: loadingCustomerBoxes || fetchingCustomerBoxes,
    setBox,
    boxes,
    box,

    loadingBoxContents: loadingBoxContents || fetchingBoxContents,
    boxContents,

    // Start packing services
    startDeliveeryChianPakcking,
    loadingStartDeliveryChainPacking,
    startCustomerDeliveryChianPacking,
    loadingStartCustomerDeliveryChainPacking,
    loadingStartBoxPacking,
    startBoxPacking,
    loadingFinishContentPacking,
    finishContentpacking,

    // Finish packing
    laodingFinishBoxPacking,
    finishBoxPacking,
    loadingFinishCustomerPacking,
    finishCustomerPacking,
    loadingFinishDeliveryChianPacking,
    finishDeliveryChainPacking,

    packingStateIds,

    packageCount,
    addExtarBox,
    loadingAddExtraBox,
    checkedContents,
    setCheckedContents,
    handleAddToCheckedContents,
    handleRemoveCheckedContents,

    downloadLabelsForCustomer,
    loadingDownlaodCustomerLabels,

    downloadLabelsForDeliveryChian,
    loadingDownloadDeliveryChianLabels,
    handleChangeDeliveryDate,
  }
}
