import {useContext, useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useSnackbar} from 'notistack'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
// services
import services, {ServerError} from 'services'
import {ICoordinates} from 'services/regions'
// models
import {Address, City, District, Province, Region} from 'models'
// dtos
import {UpdateCustomerAddressRequestDto} from 'dtos'
// utils
import {removeEmptyValues} from 'utils/basic/object'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// locals
import {texts} from './texts'
import {Props} from '.'
import Context from '../../context'

interface IUseData {
  addressId: Props['addressId']
  customerId: Props['customerId']
  handleClose: Props['handleClose']
  onSuccess: Props['onSuccess']
  show: boolean
}

const useData = ({addressId, customerId, handleClose, onSuccess, show}: IUseData) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const {selectedCustomer} = useContext(Context)
  const [addressOnMap, setAddressOnMap] = useState<Address>(new Address())
  const [address, setAddress] = useState<Address>(new Address())
  const [coordinates, setCoordinates] = useState<ICoordinates>({
    lat: '35.7219',
    long: '51.3347',
  })
  const [isSubmitted, setIsSubmitted] = useState(true)
  const [isSuccessReserveAddress, setIsSuccessReserveAddress] = useState(false)
  const [cities, setCities] = useState<City[]>([])
  const [provinces, setProvinces] = useState<Province[]>([])
  const [districts, setDistrict] = useState<District[]>([])

  const [selectedRegion, setSelectedRegion] = useState(new Region())
  const [districtsCount, setDistrictsCount] = useState(0)
  const [regionCount, setRegionCount] = useState(0)

  const {
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    setValue: setFormValue,
    formState: {errors, isValid},
  } = useForm<UpdateCustomerAddressRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(UpdateCustomerAddressRequestDto),
  })

  /* -------------------------------------------------------------------------- */
  /*                             Services                                     */
  /* -------------------------------------------------------------------------- */

  const getDistricts = async () => await services.location.getDistricts()

  const getProvinces = async () => await services.location.getProvinces()

  const getCities = async () => await services.location.getProvinceCities(watch().provinceId)

  const getCustomerAddressService = async () =>
    await services.customers.getCustomerAddress(
      selectedCustomer.getId() ? selectedCustomer.getId() : customerId!,
      addressId
    )

  const updateCustomerAddressService = async () =>
    await services.customers.updateCustomerAddress(
      selectedCustomer.getId() ? selectedCustomer.getId() : customerId!,
      addressId,
      {
        ...removeEmptyValues({
          ...watch(),
          lat: coordinates.lat,
          long: coordinates.long,
        }),
        unitNumber: 0,
        houseNumber: 0,
      }
    )

  /* -------------------------------------------------------------------------- */
  /*                             Queries                                     */
  /* -------------------------------------------------------------------------- */

  const {isLoading: customerAddressIsLoading, isSuccess: customerAddressIsSuccess} = useQuery(
    ['get-customer-address-edit-modal', selectedCustomer.getId(), customerId!, show],
    getCustomerAddressService,
    {
      onSuccess: (data) => {
        const address = new Address(data)
        setFormValue('districtId', address.getDistrict().getId())
        setFormValue('cityId', address.getCity().getId())
        setFormValue('provinceId', address.getProvince().getId())
        setFormValue('houseNumber', parseInt(address.getHouseNumber()) || 0)
        setFormValue('address', address.getAddress())
        setFormValue('streetOne', address.getStreetOne())
        setFormValue('streetTwo', address.getStreetTwo())
        setFormValue('name', address.getName())
        setFormValue('unitNumber', parseInt(address.getUnitNumber()) || 0)
        setFormValue('description', address.getDescription())
        setCoordinates({lat: address.getLat() || '35.7219', long: address.getLong() || '51.3347'})
        setAddress(address)
        reset({...getValues(), lat: coordinates.lat, long: coordinates.long})
      },
      refetchOnWindowFocus: false,
      enabled: !!addressId && !!show,
    }
  )

  const {isLoading: isLoadingProvince} = useQuery(
    ['get-provinces-edit-modal', watch().provinceId],
    getProvinces,
    {
      onSuccess: (data) => {
        setProvinces(data.map((province) => new Province(province)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  const {isLoading: isLoadingCities} = useQuery(
    ['get-cities-edit-modal', watch().provinceId],
    getCities,
    {
      onSuccess: (data) => {
        setCities(data.map((city) => new City(city)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      enabled: !!watch().provinceId,
    }
  )

  const {isLoading: isLoadingDistricts} = useQuery(
    ['get-districts-edit-modal', watch().cityId],
    getDistricts,
    {
      onSuccess: (data) => {
        setDistrict(data.map((district) => new District(district)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  /* -------------------------------------------------------------------------- */
  /*                             Mutations                                     */
  /* -------------------------------------------------------------------------- */

  const {isLoading: updateCustomerAddressIsLoading, mutateAsync} = useMutation(
    ['update-customer-details'],
    updateCustomerAddressService,
    {
      onSuccess: () => {
        handleClose()
        setIsRefetchOn(true)
        onSuccess()
        enqueueSnackbar(TX(texts.address_updated), {
          variant: 'success',
        })
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  /* -------------------------------------------------------------------------- */
  /*                                  Handlers                                  */
  /* -------------------------------------------------------------------------- */

  const onSubmit = async () => {
    await mutateAsync()
  }

  /* -------------------------------------------------------------------------- */
  /*                                 LifeCycles                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (!!addressOnMap.getAddress() || isSuccessReserveAddress) {
      setFormValue('districtId', addressOnMap.getDistrict().getId())
      setFormValue('cityId', addressOnMap.getCity().getId())
      setFormValue('provinceId', addressOnMap.getProvince().getId())
      setFormValue('address', addressOnMap.getDisplayBasicAddress())
      setFormValue('streetOne', addressOnMap.getStreetOne())
      setDistrictsCount(addressOnMap.getDistrict().getId() ? 1 : 0)
      reset({...getValues(), lat: coordinates.lat, long: coordinates.long})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressOnMap, isSuccessReserveAddress])

  useEffect(() => {
    if (show) {
      setAddressOnMap(new Address())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  /* -------------------------------------------------------------------------- */
  /*                                    Data                                    */
  /* -------------------------------------------------------------------------- */

  const provinceId = useMemo(() => {
    return watch().provinceId
  }, [watch().provinceId])

  const cityId = useMemo(() => {
    return watch().cityId
  }, [watch().cityId])

  return {
    TX,
    onSubmit: handleSubmit(onSubmit),
    control,
    provinceId,
    cityId,
    reset,
    setFormValue,
    errors,
    isValid,
    regionCount,
    setRegionCount,
    watch,
    isLoading: updateCustomerAddressIsLoading || customerAddressIsLoading,
    cities,
    provinces,
    districts,
    isLoadingProvince,
    isLoadingCities,
    isLoadingDistricts,
    selectedRegion,
    setSelectedRegion,
    districtsCount,
    setDistrictsCount,
    addressOnMap,
    setAddressOnMap,
    coordinates,
    setCoordinates,
    isSubmitted,
    setIsSubmitted,
    isSuccessReserveAddress,
    setIsSuccessReserveAddress,
    address,
    customerAddressIsSuccess,
  }
}

export {useData}
