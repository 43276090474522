import {FC} from 'react'
import {Box} from '@mui/material'
// Utils
import {toPersianDigit} from 'utils/basic/digit'
import {roundedNumber} from 'utils/basic/converters'
// models
import {ContentBox, SortingProductBox} from 'models'
// Local components
import {Checkbox} from 'components/elements/Checkbox'
import {Typography} from 'components/elements/Typography'
import {AutoComplete} from 'components/elements/AutoComplete'
// hooks
import useText from 'hooks/useText'
// Locals
import useData from './useData'
import useStyles from './useStyles'
import {texts} from '../../texts'

type Props = {
  onCheck?: (content: ContentBox, sortingProductId: string) => void
  onRemove?: (contentId: string) => void
  content?: ContentBox
}

const BoxContent: FC<Props> = ({content = new ContentBox(), onCheck = () => {}}) => {
  const {TX} = useText()
  const {classes} = useStyles()
  const {
    enqueueSnackbar,
    checked,
    setChecked,
    loading,
    sortingProducts,
    selectedProductId,
    setSelectedProductId,
  } = useData(content)

  return (
    <Box className={classes.wrapper}>
      <Checkbox
        value={checked}
        onClick={() => {
          if (checked) return
          if (!selectedProductId) {
            enqueueSnackbar(TX(texts.please_select_vendor_message), {
              variant: 'warning',
            })
            return
          }
          if (!checked) {
            setChecked(true)
            onCheck(content, selectedProductId)
          }
        }}
        disabled={checked}
      />
      <Box className={classes.descriptionWrapper}>
        <Typography type='span' color='#1A73E8'>
          {content?.getProduct().getName()}
        </Typography>
        <Typography type='span'>
          {toPersianDigit((!!content?.getProductInstanceId() ? content?.getQuantity() : 1) || 0)}{' '}
          {TX(texts.unit)}
        </Typography>
        <Typography type='span'>
          {toPersianDigit(
            (!!content?.getProductInstanceId()
              ? roundedNumber(content?.getProductInstance().getQuantity())
              : roundedNumber(content.getQuantity())) || 0
          )}{' '}
          {content.getProduct().getProductUnit().getFaName()}
        </Typography>
      </Box>
      {content.getPackingDeliveryBoxProducts()[0].getStatus() !== 'finish' && (
        <AutoComplete
          className={classes.autoComplete}
          loading={loading}
          disabled={loading || checked}
          options={sortingProducts.map((option: SortingProductBox) => ({
            value: option.getId(),
            label: `${option.getOrderLine().getProduct().getName()} - ${option
              .getOrderLine()
              .getVendor()
              .getFullName()} - ${option.getUnitQuantity()} واحد`,
          }))}
          value={selectedProductId}
          onChange={(option: {value: string; label: string} | any) => {
            setSelectedProductId(option ? option.value : undefined)
            setChecked(false)
          }}
        />
      )}
    </Box>
  )
}

export default BoxContent
