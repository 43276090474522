import {useEffect, useRef, useState} from 'react'
import {useSnackbar} from 'notistack'
import {useQuery} from 'react-query'
// services
import services, {ServerError} from 'services'
// utils
import {convertBase64ImageToFile} from 'utils/basic/image'
// hooks
import useText from 'hooks/useText'
// local
import {texts} from './texts'

type PickedImage = {url: string; size: number}

type AreaPixels = {width: number; height: number; x: number; y: number}

const useArrayBuffer = (result: ArrayBuffer | null | undefined): number => {
  if (result) return result.byteLength
  return 0
}

export const useData = (
  setXFileName: (value: string) => void,
  photoId: string,
  onClearIsFull: () => void,
  originalImage: string,
  croppedImage: string,
  onChangeOriginalImage: (value: string) => void,
  onChangeCroppedImage: (value: string) => void,
  xFileName: string
) => {
  const inputRef = useRef<HTMLInputElement>()
  const [loading, setLoading] = useState(false)
  const [loadingPercentage, setLoadingPercentage] = useState(0)
  const [insideSubmitted, setInsideSubmitted] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const {TX} = useText()
  const [showImageCropper, setShowImageCropper] = useState(false)
  const [showImageViewer, setShowImageViewer] = useState(false)
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [pickedImage, setPickedImage] = useState<PickedImage>({
    size: 0,
    url: '',
  })
  const [zoom, setZoom] = useState(2)

  /* -------------------------------------------------------------------------- */
  /*                                  Services                                  */
  /* -------------------------------------------------------------------------- */

  const getImage = (photoId: string) => async () => await services.upload.getPhoto(photoId)

  /* -------------------------------------------------------------------------- */
  /*                                   Queries                                  */
  /* -------------------------------------------------------------------------- */

  const {isLoading: loadingImage} = useQuery(['get-image', photoId], getImage(photoId), {
    enabled: !!photoId && !croppedImage,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      onChangeOriginalImage(data)
      onChangeCroppedImage(data)
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                                  Handlers                                  */
  /* -------------------------------------------------------------------------- */

  const onChangeInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onChangeOriginalImage && onChangeOriginalImage(URL.createObjectURL(e.target.files[0]))
      setPickedImage({
        url: URL.createObjectURL(e.target.files[0]),
        size: e.target.files[0].size,
      })
      e.target.value = ''
    }
    setShowImageCropper(true)
  }

  const onClickButton = () => {
    if (croppedImage) setShowImageViewer(true)
    else {
      inputRef.current?.click()
    }
  }

  const onCloseImageCropperDialog = () => {
    setShowImageCropper(false)
  }

  const onCloseImageViewer = () => {
    setShowImageViewer(false)
  }

  const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
    })

  const getCroppedImg = async (imageSrc: string, pixelCrop: AreaPixels) => {
    const image: HTMLImageElement = await createImage(imageSrc)
    image.src = imageSrc
    const canvas = document.createElement('canvas')
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    )
    return canvas.toDataURL('image/webp', 0.8)
  }

  const handleSubmitCroppedImage = async () => {
    try {
      const croppedImage: string = await getCroppedImg(pickedImage.url, croppedAreaPixels)
      onChangeCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }

  const onClickEdit = () => {
    setShowImageCropper(true)
    setShowImageViewer(false)
  }

  const onClickChange = () => {
    setShowImageViewer(false)
    inputRef.current?.click()
  }

  const onClickClear = () => {
    onChangeOriginalImage('')
    onChangeCroppedImage('')
    onClearIsFull()
    setShowImageViewer(false)
  }

  const onSubmitImage = async () => {
    setInsideSubmitted(false)
    setLoading(true)
    const imageFile = await convertBase64ImageToFile(croppedImage, 'upload-image')
    let fileSize = imageFile.size
    let chunkSize = 0.3
    let filename = Date.now() + '-' + imageFile.name
    const fileReader = new FileReader()
    fileReader.onload = async (ev) => {
      const CHUNK_SIZE = 1024 * 1024 * chunkSize
      const result = ev.target?.result
      let byteLength = 0
      if (typeof result !== 'string') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        byteLength = useArrayBuffer(result)
      }
      const chunkCount = Math.ceil(byteLength / CHUNK_SIZE)

      try {
        for (let chunkId = 0; chunkId < chunkCount; chunkId++) {
          let startChunk = chunkId * CHUNK_SIZE
          let endingChunk = Math.min(chunkId * CHUNK_SIZE + CHUNK_SIZE, fileSize)

          let endChunk = endingChunk === fileSize ? endingChunk + 1 : endingChunk
          const chunk = ev.target?.result?.slice(startChunk, endChunk)

          await services.upload.chunkFile(filename, fileSize, startChunk, chunk)
          setLoadingPercentage(chunkId * (100 / chunkCount))
        }
        setXFileName(filename)
      } catch (error) {
        setXFileName('')
        onChangeCroppedImage('')
        onChangeOriginalImage('')
        enqueueSnackbar(TX(texts.error), {
          variant: 'error',
        })
      }
      setLoading(false)
      setShowImageCropper(false)
      onCloseImageCropperDialog()
    }
    fileReader.readAsArrayBuffer(imageFile)
  }

  /* -------------------------------------------------------------------------- */
  /*                                 LifeCycles                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (croppedImage && insideSubmitted) {
      onSubmitImage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedImage, insideSubmitted])

  return {
    inputRef,
    crop,
    setCrop,
    croppedAreaPixels,
    setCroppedAreaPixels,
    zoom,
    setZoom,
    showImageCropper,
    setShowImageCropper,
    showImageViewer,
    setShowImageViewer,
    onChangeInputFile,
    onCloseImageCropperDialog,
    onSubmitImage,
    onCloseImageViewer,
    onClickButton,
    onClickEdit,
    onClickChange,
    onClickClear,
    handleSubmitCroppedImage,
    getCroppedImg,
    loadingImage,
    insideSubmitted,
    setInsideSubmitted,
    loading,
    loadingPercentage,
  }
}
