// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
import {Textarea} from 'components/elements/Textarea'
import {AutoComplete} from 'components/elements/AutoComplete'
import {Select} from 'components/elements/Select'
import {Typography} from 'components/elements/Typography'
import {JalaliDatePicker} from 'components/elements/JalaliCalendar'
import {MapPin} from 'components/elements/MapPin'
// models
import {Address, RegionDeliveryPlanStatusEnum, displayCustomerStatusEnum} from 'models'
// enums
import {CustomerStatusEnum} from 'enums'
//hooks
import useText from 'hooks/useText'
import {useHorizontalScroll} from 'hooks/useHorizontalScroll'
// local
import {useData} from './useData'
import {texts} from './texts'
import {Controller} from 'react-hook-form'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateSubscriberModal = ({show, handleClose}: Props) => {
  const data = useData(show, handleClose)
  const {TX} = useText()
  const scrollHorizontalRef = useHorizontalScroll()

  return (
    <Modal
      id='create-subscriber-modal'
      show={show}
      handleClose={() => {
        handleClose()
        data.reset()
      }}
      title={TX(texts.create_subscriber_title)}
      children={
        <form onSubmit={data.onSubmit}>
          <div className='container ' id='kt_modal_create_app_stepper'>
            <div>
              <div className='row my-5'>
                <div className='col-sm'>
                  <Controller
                    name='firstName'
                    control={data.control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        label={TX(texts.firstName)}
                        name='firstName'
                        type='text'
                        autoComplete='off'
                        error={!!data.errors.firstName}
                        touched={!!data.errors.firstName?.message}
                        errorText={data.errors.firstName?.message}
                      />
                    )}
                  />
                </div>
                <div className='col-sm'>
                  <Controller
                    name='lastName'
                    control={data.control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        name='lastName'
                        type='text'
                        label={TX(texts.lastName)}
                        error={!!data.errors.lastName}
                        touched={!!data.errors.lastName?.message}
                        errorText={data.errors.lastName?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='row my-5'>
                <div className='col-sm'>
                  <Controller
                    name='mobileNumber'
                    control={data.control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        label={TX(texts.mobile_number)}
                        id='mobileNumber'
                        name='mobileNumber'
                        type='text'
                        error={!!data.errors.mobileNumber}
                        touched={!!data.errors.mobileNumber?.message}
                        errorText={data.errors.mobileNumber?.message}
                      />
                    )}
                  />
                </div>
                <div className='col-sm '>
                  <Controller
                    name='secondMobileNumber'
                    control={data.control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        label={TX(texts.second_mobile_number)}
                        id='secondMobileNumber'
                        name='secondMobileNumber'
                        type='text'
                        error={!!data.errors.secondMobileNumber}
                        touched={!!data.errors.secondMobileNumber?.message}
                        errorText={data.errors.secondMobileNumber?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='row my-5'>
                <div className='col-sm-6'>
                  <Select
                    options={Object.values(CustomerStatusEnum)}
                    formatOption={displayCustomerStatusEnum}
                    value={data.statusValue}
                    onChange={(type) =>
                      data.setFormValue('status', type as CustomerStatusEnum, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                    label={TX(texts.status_title)}
                  />
                  <Typography type='span' className='fs-7 mt-5' color='rgb(231, 76, 60)'>
                    {data.errors.status?.message}
                  </Typography>
                </div>
                <div className='col-sm'>
                  <Controller
                    name='email'
                    control={data.control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        label={TX(texts.email)}
                        type='text'
                        id='email'
                        name='email'
                        error={!!data.errors.email}
                        touched={!!data.errors.email?.message}
                        errorText={data.errors.email?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='row my-5'>
                <div className='col-sm-6'>
                  <JalaliDatePicker
                    id='birthDate'
                    name='birthDate'
                    label={TX(texts.birth_day)}
                    value={
                      data.birthDateValue as unknown as
                        | (Date & (string | number | readonly string[]))
                        | undefined
                    }
                    error={!!data.errors.birthDate}
                    onChange={data.handleChangeBirthDate}
                    disabled={data.isLoading}
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <Typography type='h4'>{TX(texts.address_info)}</Typography>
              <div
                style={
                  !!data.addressOnMap.getAddress()
                    ? {
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                    : {}
                }
              >
                <MapPin
                  coordinates={data.coordinates}
                  setCoordinates={data.setCoordinates}
                  setAddress={(value: Address) => data.setAddressOnMap(value)}
                  clean={!show}
                />
              </div>
              {!!data.addressOnMap.getAddress() && (
                <>
                  <div className='row my-5'>
                    <div className='col-sm'>
                      <AutoComplete
                        loading={data.isLoadingProvince}
                        options={data.provinces.map((option: any) => ({
                          value: option.props.id,
                          label: option.props.label,
                        }))}
                        label={TX(texts.province)}
                        value={data.provinceId}
                        onChange={(option: {value: string; label: string} | any) => {
                          data.setFormValue('address.provinceId', option ? option.value : '', {
                            shouldValidate: true,
                            shouldDirty: true,
                          })
                          data.setFormValue('address.cityId', '', {
                            shouldValidate: true,
                            shouldDirty: true,
                          })
                          data.setFormValue('address.districtId', '')
                          data.setDistrictsCount(0)
                          data.setRegionCount(0)
                        }}
                      />
                    </div>
                    <div className='col-sm'>
                      <AutoComplete
                        loading={data.isLoadingCities}
                        disabled={!data.provinceId}
                        options={data.cities.map((option: any) => ({
                          value: option.props.id,
                          label: option.props.label,
                          districtsCount: option.props.districtsCount,
                        }))}
                        label={TX(texts.city)}
                        value={data.cityId}
                        onChange={(
                          option: {value: string; label: string; districtsCount: number} | any
                        ) => {
                          data.setFormValue('address.cityId', option ? option.value : '', {
                            shouldValidate: true,
                            shouldDirty: true,
                          })
                          data.setFormValue('address.districtId', '')
                          data.setDistrictsCount(option ? option.districtsCount : 0)
                        }}
                        error={!data.districtsCount}
                        errorText={
                          !!data.addressOnMap.getCityId() ? TX(texts.out_of_service_range) : ''
                        }
                      />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-sm'>
                      <div className='col-sm'>
                        <Controller
                          name='address.streetOne'
                          control={data.control}
                          render={({field}) => (
                            <Textfield
                              {...field}
                              label={TX(texts.address_title)}
                              type='text'
                              autoComplete='off'
                              error={!!data.errors.address?.streetOne}
                              touched={!!data.errors.address?.streetOne?.message}
                              errorText={data.errors.address?.streetOne?.message}
                              disabled={data.isLoading || !data.addressOnMap.getAddress()}
                              name='address.streetOne'
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-sm'>
                      <Controller
                        name='address.name'
                        control={data.control}
                        render={({field}) => (
                          <Textfield
                            {...field}
                            label={TX(texts.address_label)}
                            id='addressName'
                            name='address.name'
                            type='text'
                            error={!!data.errors.address?.name}
                            touched={!!data.errors.address?.name?.message}
                            errorText={data.errors.address?.name?.message}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                    <div className='col-sm'>
                      <Controller
                        name='address.streetTwo'
                        control={data.control}
                        render={({field}) => (
                          <Textfield
                            {...field}
                            label={TX(texts.address_description)}
                            type='text'
                            autoComplete='off'
                            error={!!data.errors.address?.streetTwo}
                            touched={!!data.errors.address?.streetTwo?.message}
                            errorText={data.errors.address?.streetTwo?.message}
                            disabled={data.isLoading}
                            name='address.streetTwo'
                          />
                        )}
                      />
                    </div>
                  </div>

                  {data.addressOnMap.getRegion().getRegionDeliveryPlan().length > 0 && (
                    <>
                      <Typography type='h5' className='mt-10'>
                        {TX(texts.delivery_plan)}
                      </Typography>
                      <div className='card' style={{backgroundColor: '#FFF3E0'}}>
                        <div
                          className='p-5 mb-xl-10 d-flex flex-row justify-start align-items-center gap-5 overflow-scroll'
                          ref={scrollHorizontalRef}
                        >
                          {data.addressOnMap
                            .getRegion()
                            .getRegionDeliveryPlan()
                            .filter(
                              (regionDeliveryPlan) =>
                                regionDeliveryPlan.getStatus() ===
                                RegionDeliveryPlanStatusEnum.activate
                            )
                            .map((item) => (
                              <div
                                style={{backgroundColor: '#bbf0a4'}}
                                className='flex-shrink-0 border border-gray-800 p-5 rounded d-flex flex-row gap-5 flex-wrap align-items-start'
                              >
                                <div key={item.getId()} className='p-1 '>
                                  {item.getDeliveryPlan().getDisplayDayOfWeek()} -{' '}
                                  {TX(texts.count_capacity, [
                                    item.getDeliveryPlan().getDisplayShift(),
                                  ])}{' '}
                                  {item.getCourierCapacity()}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  )}
                  <div className='row mt-5'>
                    <div className='col-sm'>
                      <Controller
                        name='description'
                        control={data.control}
                        render={({field}) => (
                          <Textarea
                            {...field}
                            id='description'
                            name='description'
                            type='text'
                            label={TX(texts.description)}
                            className='form-control form-control-lg form-control-solid h-25'
                          />
                        )}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                <Button
                  text={TX(texts.back)}
                  className='main-red-background btn btn-lg background-inherit justify-content-center'
                  onClick={() => {
                    handleClose()
                    data.reset()
                  }}
                  type='button'
                />

                <Button
                  text={TX(texts.submit)}
                  className='btn btn-lg btn-primary main-gradient-background'
                  isLoading={data.isLoading}
                  disabled={!data.isValid}
                />
              </div>
            </div>
          </div>
        </form>
      }
    />
  )
}

export {CreateSubscriberModal}
