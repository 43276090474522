import {IsDate, IsEnum, IsInt, IsNotEmpty, IsOptional, IsString, ValidateIf} from 'class-validator'
import {BoxStatusEnum} from 'enums'

export class CreateBoxRequestDto {
  @IsString({message: 'نام باکس الزامی است'})
  @IsNotEmpty({message: 'نام باکس الزامی است'})
  name: string

  @IsOptional()
  @IsString()
  photoId?: string

  @IsOptional()
  @IsString()
  description?: string

  @IsOptional()
  @IsInt({message: 'اولویت باید یک عدد صحیح باشد'})
  @ValidateIf((_, value) => value !== null)
  priority?: number | null

  @IsOptional()
  @IsEnum(BoxStatusEnum)
  status?: BoxStatusEnum

  @IsOptional()
  @IsDate({message: 'تاریخ انقضا صحیح نیست'})
  expiryDate?: Date

  constructor() {
    this.name = ''
    this.photoId = ''
    this.description = ''
    this.priority = 1
    this.status = '' as BoxStatusEnum
    this.expiryDate = new Date()
  }
}
