import {
  TransactionCreatedByEnum,
  TransactionPaymentTypeEnum,
  TransactionStatusEnum,
  TransactionTypeEnum,
} from 'enums'
import {Admin, IAdmin} from './Admin'
import {Customer, ICustomer} from './Customer'
import {Delivery, IDelivery} from './Delivery'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {ISubscription, Subscription} from './Subscription'
import {IWallet, Wallet} from './Wallet'

export interface ITransaction {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  amount?: number
  amountOnline?: number
  amountCard?: number
  amountWallet?: number
  createdBy?: TransactionCreatedByEnum
  type?: TransactionTypeEnum
  status?: TransactionStatusEnum
  paymentType?: TransactionPaymentTypeEnum
  txnDetails?: string
  subscriptionId?: string
  subscription?: ISubscription
  deliveryId?: string
  delivery?: IDelivery
  customerId?: string
  adminId?: string
  customer?: ICustomer
  admin?: IAdmin
  photoUses?: IPhotoUse[]
  walletId?: string
  wallet?: IWallet
  description?: string
  token?: string
  statusNumber?: number
  statusMessage?: string
  terminalNumber?: number
  orderId?: string
  rrn?: string
  cardNumber?: string
  primaryTraceNumber?: number
  secondaryTraceNumber?: number
}

export class Transaction {
  public props: ITransaction = {}

  constructor(data?: ITransaction) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt ? new Date(this.props.createdAt) : new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : new Date()
  }

  getAmount(): number {
    return this.props.amount || 0
  }

  getAmountOnline(): number {
    return this.props.amountOnline || 0
  }

  getAmountCard(): number {
    return this.props.amountCard || 0
  }

  getAmountWallet(): number {
    return this.props.amountWallet || 0
  }

  getCreatedBy(): TransactionCreatedByEnum {
    return this.props.createdBy as TransactionCreatedByEnum
  }

  getType(): TransactionTypeEnum {
    return this.props.type as TransactionTypeEnum
  }

  getStatus(): TransactionStatusEnum {
    return this.props.status as TransactionStatusEnum
  }

  getPaymentType(): TransactionPaymentTypeEnum {
    return this.props.paymentType as TransactionPaymentTypeEnum
  }

  getTnxDetails(): string {
    return this.props.txnDetails || ''
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getSubscription(): Subscription {
    return new Subscription(this.props.subscription || {})
  }

  getDeliveryId(): string {
    return this.props.deliveryId || ''
  }

  getDelivery(): Delivery {
    return new Delivery(this.props.delivery || {})
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || undefined)
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || []).map((photoUse: IPhotoUse) => new PhotoUse(photoUse))
  }

  getWalletId(): string {
    return this.props.walletId || ''
  }

  getWallet(): Wallet {
    return new Wallet(this.props.wallet || {})
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getToken(): string {
    return this.props.token || ''
  }

  getStatusNumber(): number {
    return this.props.statusNumber || 0
  }

  getStatusMessage(): string {
    return this.props.statusMessage || ''
  }

  getTerminalNumber(): number {
    return this.props.terminalNumber || 0
  }

  getOrderId(): string {
    return this.props.orderId || ''
  }

  getRRN(): string {
    return this.props.rrn || ''
  }

  getCardNumber(): string {
    return this.props.cardNumber || ''
  }

  getPrimaryTraceNumber(): number {
    return this.props.primaryTraceNumber || 0
  }

  getSecondaryTraceNumber(): number {
    return this.props.secondaryTraceNumber || 0
  }

  /* -------------------------------------------------------------------------- */
  /*                             Customs                                       */
  /* -------------------------------------------------------------------------- */

  getCreatedAtTimeDisplay(): string {
    const date = this.getCreatedAt()
    return date.toLocaleTimeString('fa', {hour12: false, hour: '2-digit', minute: 'numeric'})
  }

  getAmountDisplay(): string {
    return this.props.amount + ' تومان'
  }

  displayStatus(): string {
    return displayTransactionStatusEnum(this.getStatus())
  }
}

export const displayTransactionStatusEnum = (
  transactionStatusEnum: TransactionStatusEnum
): string => {
  switch (transactionStatusEnum) {
    case TransactionStatusEnum.pending:
      return 'در انتظار پرداخت'
    case TransactionStatusEnum.paid:
      return 'پرداخت شده'
    case TransactionStatusEnum.expired:
      return 'منقضی شده'
    case TransactionStatusEnum.failed:
      return 'رد شده'
    case TransactionStatusEnum.refunded:
      return 'بازگشت داده شده'
    case TransactionStatusEnum.waiting_to_accept:
      return 'در انتظار تایید'
    default:
      return ''
  }
}

export const displayTransactionType = (transactionType: TransactionTypeEnum): string => {
  switch (transactionType) {
    case TransactionTypeEnum.change_to_cheaper_box:
      return 'تغییر به جعبه ارزانتر'
    case TransactionTypeEnum.change_to_more_expensive_box:
      return 'تغییر به جعبه گرانتر'
    case TransactionTypeEnum.buy_subscription:
      return 'ثبت سفارش'
    case TransactionTypeEnum.refunded:
      return 'کارت به کارت'
    case TransactionTypeEnum.wallet:
      return 'اعتبار'
    case TransactionTypeEnum.cancel_subscription:
      return 'لغو سفارش'
    case TransactionTypeEnum.cancel_delivery:
      return 'لغو نوبت'
    case TransactionTypeEnum.wallet_deposit:
      return 'سپرده'
    case TransactionTypeEnum.wallet_withdrawal:
      return 'برداشت از اعتبار'
    case TransactionTypeEnum.wallet_admin_gift:
      return 'افزایش اعتبار'
    case TransactionTypeEnum.wallet_coupon_activation:
      return 'فعال سازی اعتبار (اعتبار کد)'
    case TransactionTypeEnum.wallet_coupon_registration:
      return 'فعال سازی اعتبار (اعتبار ثبت نام)'
    default:
      return '-'
  }
}

export const displayTransactionPaymentType = (
  transactionPaymentType: TransactionPaymentTypeEnum
): string => {
  switch (transactionPaymentType) {
    case TransactionPaymentTypeEnum.online:
      return 'پرداخت آنلاین'
    case TransactionPaymentTypeEnum.credit:
      return 'کارت به کارت'
    case TransactionPaymentTypeEnum.wallet:
      return 'اعتبار'
    default:
      return '-'
  }
}

export const displayTransactionCreatedByEnum = (
  transactionCreatedBy: TransactionCreatedByEnum
): string => {
  switch (transactionCreatedBy) {
    case TransactionCreatedByEnum.admin:
      return 'ادمین'
    case TransactionCreatedByEnum.customer:
      return 'مشتری'
    default:
      return '-'
  }
}
