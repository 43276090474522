import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsInt,
  IsNumber,
  IsOptional,
  IsString,
  ValidateIf,
} from 'class-validator'
import {BoxStatusEnum} from 'enums'

export class UpdateBoxRequestDto {
  @IsString()
  @IsOptional()
  name?: string

  @IsOptional()
  @IsString()
  photoId?: string

  @IsOptional()
  @IsString()
  description?: string

  @IsOptional()
  @IsInt({message: 'اولویت باید یک عدد صحیح باشد'})
  @ValidateIf((_, value) => value !== null)
  priority?: number | null

  @IsOptional()
  @IsEnum(BoxStatusEnum)
  status?: BoxStatusEnum

  @IsOptional()
  @IsDate({message: 'تاریخ انقضا صحیح نیست'})
  expiryDate?: Date

  @IsOptional()
  @IsBoolean()
  useCustomPrice?: boolean

  @IsOptional()
  @IsNumber()
  customPrice?: number

  constructor() {
    this.name = ''
    this.photoId = ''
    this.description = ''
    this.priority = 0
    this.status = '' as BoxStatusEnum
    this.expiryDate = new Date()
    this.customPrice = 0
    this.useCustomPrice = false
  }
}
