import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useSnackbar} from 'notistack'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
// services
import services, {ServerError} from 'services'
import {ICoordinates} from 'services/regions'
// models
import {Address, City, District, Province, Region} from 'models'
// dtos
import {CreateCustomerAddressRequestDto} from 'dtos'
// utils
import {removeEmptyValues} from 'utils/basic/object'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// locals
import {texts} from './texts'
import {Props} from '.'

interface IUseData {
  handleClose: Props['handleClose']
  onSuccess: Props['onSuccess']
  show: boolean
  customerId: string
}

const useData = ({handleClose, onSuccess, show, customerId}: IUseData) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const [addressOnMap, setAddressOnMap] = useState<Address>(new Address())
  const [coordinates, setCoordinates] = useState<ICoordinates>({
    lat: '35.7219',
    long: '51.3347',
  })
  const [cities, setCities] = useState<City[]>([])
  const [provinces, setProvinces] = useState<Province[]>([])
  const [districts, setDistrict] = useState<District[]>([])

  const [selectedRegion, setSelectedRegion] = useState(new Region())
  const [districtsCount, setDistrictsCount] = useState(0)
  const [regionCount, setRegionCount] = useState(0)

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue: setFormValue,
    formState: {errors, isValid},
  } = useForm<CreateCustomerAddressRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateCustomerAddressRequestDto),
    defaultValues: {
      name: '',
      provinceId: '',
      cityId: '',
      districtId: '',
      address: '',
      streetOne: '',
      houseNumber: 0,
      unitNumber: 0,
      streetTwo: '',
      lat: coordinates.lat,
      long: coordinates.long,
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                             Services                                     */
  /* -------------------------------------------------------------------------- */

  const getDistricts = async () => await services.location.getDistricts()

  const getProvinces = async () => await services.location.getProvinces()

  const getCities = async () => await services.location.getProvinceCities(watch().provinceId)

  const createCustomerAddressService = async () =>
    await services.customers.createCustomerAddress(customerId, {
      ...removeEmptyValues({
        ...watch(),
        lat: coordinates.lat,
        long: coordinates.long,
      }),
      unitNumber: 0,
      houseNumber: 0,
    })

  /* -------------------------------------------------------------------------- */
  /*                             Queries                                     */
  /* -------------------------------------------------------------------------- */

  const {isLoading: isLoadingProvince} = useQuery(
    ['get-provinces-create-modal', watch().provinceId],
    getProvinces,
    {
      onSuccess: (data) => {
        setProvinces(data.map((province) => new Province(province)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  const {isLoading: isLoadingCities} = useQuery(
    ['get-cities-create-modal', watch().provinceId],
    getCities,
    {
      onSuccess: (data) => {
        setCities(data.map((city) => new City(city)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      enabled: !!watch().provinceId,
    }
  )

  const {isLoading: isLoadingDistricts} = useQuery(
    ['get-districts-create-modal', watch().cityId],
    getDistricts,
    {
      onSuccess: (data) => {
        setDistrict(data.map((district) => new District(district)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  /* -------------------------------------------------------------------------- */
  /*                             Mutations                                     */
  /* -------------------------------------------------------------------------- */

  const {isLoading: createCustomerAddressIsLoading, mutateAsync} = useMutation(
    ['create-customer-details'],
    createCustomerAddressService,
    {
      onSuccess: () => {
        handleClose()
        setIsRefetchOn(true)
        reset()
        onSuccess()
        enqueueSnackbar(TX(texts.address_created), {
          variant: 'success',
        })
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  /* -------------------------------------------------------------------------- */
  /*                                  Handlers                                  */
  /* -------------------------------------------------------------------------- */

  const onSubmit = async () => {
    await mutateAsync()
  }

  /* -------------------------------------------------------------------------- */
  /*                                 LifeCycles                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (!!addressOnMap.getAddress()) {
      setFormValue('address', addressOnMap.getDisplayBasicAddress())
      setFormValue('streetOne', addressOnMap.getStreetOne())
      setFormValue('cityId', addressOnMap.getCity().getId())
      setFormValue('provinceId', addressOnMap.getProvince().getId())
      setFormValue('districtId', addressOnMap.getDistrict().getId())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressOnMap])

  useEffect(() => {
    if (show) {
      reset()
      setCoordinates({
        lat: '35.7219',
        long: '51.3347',
      })
      setAddressOnMap(new Address())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  /* -------------------------------------------------------------------------- */
  /*                                    Data                                    */
  /* -------------------------------------------------------------------------- */

  const provinceId = useMemo(() => {
    return watch().provinceId
  }, [watch().provinceId])

  const cityId = useMemo(() => {
    return watch().cityId
  }, [watch().cityId])

  return {
    TX,
    cities,
    provinces,
    districts,
    isLoading: createCustomerAddressIsLoading,
    isLoadingProvince,
    isLoadingCities,
    isLoadingDistricts,
    selectedRegion,
    setSelectedRegion,
    districtsCount,
    setDistrictsCount,
    onSubmit: handleSubmit(onSubmit),
    control,
    provinceId,
    cityId,
    reset,
    setFormValue,
    errors,
    isValid,
    regionCount,
    setRegionCount,
    addressOnMap,
    setAddressOnMap,
    coordinates,
    setCoordinates,
  }
}

export {useData}
