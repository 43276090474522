import {FC} from 'react'
import Cropper from 'react-easy-crop'
// mui
import {IconButton, Slider} from '@mui/material'
import {Close as CloseIcon} from '@mui/icons-material'
import {Delete as DeleteIcon} from '@mui/icons-material'
import {Edit as EditIcon} from '@mui/icons-material'
// utils
import {toPersianDigit} from 'utils/basic/digit'
// hooks
import useText from 'hooks/useText'
// locals
import {useStyles} from './style'
import {assets} from './assets'
import {useData} from './useData'
import {texts} from './texts'
import {Button} from '../Button'
import Image from '../Image'

interface IUploadImage {
  buttonRef?: React.MutableRefObject<HTMLDivElement | undefined>
  photoId?: string
  aspect?: number
  placeholderImage?: string
  width?: string
  height?: string
  circular?: boolean
  disabled?: boolean
  xFileName?: string
  setXFileName?: (value: string) => void
  onClean?: (clean: React.SetStateAction<boolean>) => void
  onClearIsFull?: () => void
  originalImage?: string
  croppedImage?: string
  onChangeOriginalImage?: (value: string) => void
  onChangeCroppedImage?: (value: string) => void
}

const UploadImageChunk: FC<IUploadImage> = ({
  photoId = '',
  aspect = 4 / 3,
  placeholderImage = assets.images.placeholder.src,
  width = '72px',
  height = '84px',
  buttonRef,
  circular,
  disabled = false,
  setXFileName = () => {},
  onClearIsFull = () => {},
  originalImage = '',
  croppedImage = '',
  onChangeOriginalImage = () => {},
  onChangeCroppedImage = () => {},
  xFileName = '',
}) => {
  const {classes} = useStyles()
  const {TX} = useText()
  const {
    inputRef,
    crop,
    setCrop,
    setCroppedAreaPixels,
    zoom,
    setZoom,
    onChangeInputFile,
    showImageCropper,
    onCloseImageCropperDialog,
    onClickButton,
    onClickEdit,
    onClickChange,
    onClickClear,
    handleSubmitCroppedImage,
    setInsideSubmitted,
    loading,
    loadingPercentage,
    loadingImage,
  } = useData(
    setXFileName,
    photoId,
    onClearIsFull,
    originalImage,
    croppedImage,
    onChangeOriginalImage,
    onChangeCroppedImage,
    xFileName
  )

  const renderInput = () => {
    return (
      <input
        type='file'
        onChange={(e) => {
          onChangeInputFile(e)
          e.target.value = ''
        }}
        accept={'image/*'}
        ref={inputRef as React.MutableRefObject<HTMLInputElement>}
        style={{display: 'none'}}
      />
    )
  }

  const renderImageCropper = () => {
    if (showImageCropper) {
      return (
        <div className={classes.masked}>
          <div className='btn btn-icon btn-active-color-secondary bg-white w-40px position-absolute top-0 start-0 m-5'>
            <IconButton
              color='error'
              onClick={onCloseImageCropperDialog}
              disabled={disabled || loading}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.cropperContainer}>
            <Cropper
              image={originalImage}
              zoom={zoom}
              onZoomChange={setZoom}
              crop={crop}
              onCropChange={setCrop}
              aspect={aspect}
              onCropComplete={(croppedArea, croppedAreaPixels) =>
                setCroppedAreaPixels(croppedAreaPixels)
              }
            />
          </div>
          <div className={classes.cropperSlider}>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              disabled={disabled || loading}
              onChange={(e, newValue) => setZoom(Number(newValue))}
            />
          </div>
          <div className={classes.cropperActions}>
            <Button
              type='button'
              className='w-75 justify-content-center m-auto'
              onClick={() => {
                handleSubmitCroppedImage()
                setInsideSubmitted(true)
              }}
              isLoading={loading}
              disabled={disabled || loading}
              loadingText={TX(texts.loading, [toPersianDigit(Math.ceil(loadingPercentage))])}
            >
              {TX(texts.record_image_edits)}
            </Button>
          </div>
        </div>
      )
    }
  }

  const renderButton = () => {
    return (
      <>
        {renderInput()}

        <div className='image-input square border border-2'>
          <div
            className='cursor-pointer'
            style={{background: '#F5F8FA'}}
            onClick={() => {
              if (!!croppedImage) {
                onClickChange()
              } else {
                onClickButton()
              }
            }}
          >
            <Image
              isLoading={loadingImage}
              disableDialog
              src={croppedImage}
              alt=''
              width={Number(width.replace('px', ''))}
              height={Number(height.replace('px', ''))}
            />
          </div>
          {!!croppedImage && (
            <>
              <div className='btn btn-icon btn-active-color-secondary w-40px position-absolute bottom-0'>
                <IconButton color='error' onClick={onClickClear}>
                  <DeleteIcon />
                </IconButton>
              </div>
              <div className='btn btn-icon btn-active-color-secondary w-40px position-absolute bottom-0 end-0'>
                <IconButton color='warning' onClick={onClickEdit}>
                  <EditIcon />
                </IconButton>
              </div>
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <div className={classes.root}>
      {renderInput()}
      {renderImageCropper()}
      {renderButton()}
    </div>
  )
}

export default UploadImageChunk
