import {forwardRef, RefAttributes, useEffect, useState} from 'react'
import {KTSVG} from 'utils/template/helpers'

interface CounterProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  className?: string
  labelClassName?: string
  label?: string
  value?: number
  placeholder?: string
  onChange?: (value: number) => void
  helperText?: string
  disabled?: boolean
  error?: boolean
  errorText?: string
  isPercentage?: boolean
}

type Props = CounterProps & RefAttributes<HTMLInputElement>

const Counter = forwardRef<HTMLInputElement, Props>(
  (
    {
      className = '',
      labelClassName,
      label,
      value,
      placeholder = '',
      onChange = () => null,
      helperText = '',
      disabled = false,
      error = false,
      errorText = '',
      isPercentage = false,
      ...props
    },
    ref
  ) => {
    const [initialValue, setInitialValue] = useState<number | undefined>(value!)

    useEffect(() => {
      setInitialValue(value)
    }, [value])

    return (
      <div className='fv-row w-100'>
        <label
          className={['d-flex align-items-center fs-5 fw-semibold mb-2', labelClassName].join(' ')}
        >
          <span>{label}</span>
        </label>
        <div className='input-group input-group-sm'>
          <span
            className='input-group-text cursor-pointer'
            onClick={() => {
              if (!disabled) {
                if (isPercentage) {
                  if (value === 100) {
                    return null
                  }
                }
                onChange(value! + 1)
              }
            }}
          >
            <KTSVG
              className='svg-icon main-green'
              svgClassName='mh-100px h-20px'
              path='/media/icons/duotune/art/add-sarsabad.svg'
            />
          </span>
          <input
            {...props}
            ref={ref}
            type='number'
            step='0.01'
            className={[
              'form-control text-center',
              className,
              disabled ? 'text-gray-500' : '',
            ].join(' ')}
            value={Number(initialValue) <= 0 ? '' : value}
            onChange={(e) => {
              if (!/[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(e.target.value)) {
                onChange(0)
                setInitialValue(0)
                return null
              }
              if (isPercentage && parseFloat(e.target.value) > 100) {
                onChange(100)
                return null
              }
              if ((e.target.value.split('.')[1] ?? '0').length >= 2 && value) {
                onChange(value)
                return
              }
              onChange(parseFloat(parseFloat(e.target.value).toFixed(1)))
            }}
            min={0}
            lang='en-US'
            placeholder={placeholder}
            style={{backgroundColor: '#F5F8FA'}}
            disabled={disabled}
          />
          <span
            className='input-group-text cursor-pointer'
            onClick={() => !disabled && value && onChange(value - 1)}
          >
            <KTSVG
              className='svg-icon main-green'
              svgClassName='mh-100px h-20px'
              path='/media/icons/duotune/art/remove-sarsabad.svg'
            />
          </span>
        </div>
        <span className='fw-normal fs-8 text-gray-500'>{helperText}</span>
        {error && (
          <div className='fv-plugins-message-container text-danger fs-7'>
            <div className='fv-help-block'>{errorText}</div>
          </div>
        )}
      </div>
    )
  }
)

export {Counter}
