import {FC, useEffect, useMemo, useRef} from 'react'
import {LatLngLiteral} from 'leaflet'
// component
import {Box} from '@mui/material'
import {Button} from 'components/elements/Button'
import Map from 'components/elements/Map'
import {Typography} from 'components/elements/Typography'
import {AutoComplete} from '../AutoComplete'
// services
import {ICoordinates} from 'services/regions'
// models
import {Address} from 'models'
// hook
import useText from 'hooks/useText'
// local
import {useData} from './useData'
import {texts} from './texts'
import {useStyles} from './styles'

interface IMapPin {
  coordinates: ICoordinates
  setCoordinates: React.Dispatch<React.SetStateAction<ICoordinates>>
  setAddress: (value: Address) => void
  clean?: boolean
  isSubmitted?: boolean
  setIsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>
  setIsSuccessReserveAddress?: React.Dispatch<React.SetStateAction<boolean>>
}

export const MapPin: FC<IMapPin> = ({
  coordinates,
  setCoordinates,
  setAddress,
  clean = false,
  isSubmitted = false,
  setIsSubmitted = () => {},
  setIsSuccessReserveAddress = () => {},
}) => {
  const mapLocationRef = useRef<L.Marker | null>(null)
  const {TX} = useText()
  const data = useData(setAddress, clean, setIsSuccessReserveAddress)

  useEffect(() => {
    if (data.isSubmitted) {
      data.onReserve({
        lat: coordinates?.lat || '',
        long: coordinates?.long || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates])

  // useEffect(() => {
  //   if (isSubmitted) {
  //     data.onReserve({
  //       lat: coordinates?.lat || '',
  //       long: coordinates?.long || '',
  //     })
  //     data.setIsSubmitted(true)
  //     setIsSubmitted(false)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSubmitted])

  const dragPinHandler = useMemo(
    () => ({
      dragend() {
        const marker = mapLocationRef.current
        if (marker != null) {
          const {lat, lng} = marker.getLatLng()
          setCoordinates({lat: String(lat), long: String(lng)})
          return {lat, lng}
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const changePinHandler = (marker: LatLngLiteral) => {
    if (marker != null) {
      const {lat, lng} = marker
      setCoordinates({lat: String(lat), long: String(lng)})
    }
  }

  const {classes, cx} = useStyles()

  const renderSearch = () => {
    return (
      <Box className={classes.searchFieldWrapper}>
        <AutoComplete
          options={data.searchedAddresses.map((value) => ({
            value: [value.location.y, value.location.x].join(','),
            label: `${value.title} ${
              typeof value.address !== 'undefined' ? `(${value.address})` : ''
            } - ${typeof value.neighbourhood !== 'undefined' ? `${value.neighbourhood} -` : ''} ${
              value.region
            }`,
          }))}
          placeholder={TX(texts.search)}
          className={['form-control bg-white', classes.searchInput].join(' ')}
          value={[coordinates.lat, coordinates.long].join(',')}
          onChange={(option: {value: string; label: string} | any) => {
            option &&
              changePinHandler({
                lat: parseFloat(option.value.split(',')[0]),
                lng: parseFloat(option.value.split(',')[1]),
              })
            data.setIsMapSearched(true)
          }}
          loading={data.isLoadingSearch}
          inputValue={data.form.values.searchedText}
          onInputChange={(newValue: string) => {
            data.form.setFieldValue(`searchedText`, newValue, false)
          }}
          menuPlacement={'bottom'}
        />
      </Box>
    )
  }

  const renderMap = () => {
    return (
      <div className={cx(classes.container, 'mb-20')}>
        <div
          style={
            !!data.selectedAddress.getAddress() && data.isSubmitted ? {pointerEvents: 'none'} : {}
          }
        >
          <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
            <Box
              position={'relative'}
              zIndex={9}
              overflow={'hidden'}
              className={classes.totalBoxAddress}
            >
              <Box position={'relative'}>
                <Map
                  mapLocationRef={mapLocationRef}
                  onChangePinHandler={(marker: LatLngLiteral) => changePinHandler(marker)}
                  onDragPinHandler={dragPinHandler}
                  scrollWheelZoomMap={true}
                  isChange={!data.isSubmitted}
                  isDraggable={!data.isSubmitted}
                  coordinates={{
                    lat: parseFloat(coordinates.lat),
                    lng: parseFloat(coordinates.long),
                  }}
                  height={280}
                  isSearched={data.isMapSearched}
                />
                <Box className={classes.boxPositionMap}>{renderSearch()}</Box>
              </Box>
            </Box>
          </Box>
        </div>
        {!data.isSubmitted && (
          <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
            <Button
              type='button'
              className={cx([classes.buttonSave, 'justify-content-center'].join(' '))}
              onClick={() => {
                data.onReserve({
                  lat: coordinates?.lat || '',
                  long: coordinates?.long || '',
                })
              }}
              isLoading={data.isLoadingReserve}
            >
              <Typography type='span' color='#FFFFFF' className='pt-1 text-center'>
                {TX(texts.confirmLocation)}
              </Typography>
            </Button>
          </Box>
        )}
        {data.isSubmitted && (
          <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
            <Button
              type='button'
              className={cx([classes.buttonSave, 'justify-content-center'].join(' '))}
              onClick={() => data.setIsSubmitted(false)}
            >
              <Typography type='span' color='#FFFFFF' className='pt-1 text-center'>
                {TX(texts.editLocation)}
              </Typography>
            </Button>
          </Box>
        )}
      </div>
    )
  }

  const renderNoReserve = () => {
    return (
      <div className={cx(classes.container, 'mb-5')}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Box
            className={classes.headerContainer}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <Typography type='h3' color='#00853A' className='mb-0'>
              {TX(texts.not_have_shipping_in_this_area_yet)}
            </Typography>
          </Box>
          <Button
            type='button'
            className={cx([classes.buttonSave, 'justify-content-center'].join(' '))}
            onClick={data.handleClose}
          >
            <Typography type='span' color='#FFFFFF' className='pt-1 text-center'>
              {TX(texts.understood)}
            </Typography>
          </Button>
        </Box>
      </div>
    )
  }

  if (data.hasNoService) return renderNoReserve()
  return renderMap()
}
