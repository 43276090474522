import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {useFormik} from 'formik'
import {useMutation, useQuery} from 'react-query'
// services
import services, {ServerError} from 'services'
import {IAddressSearch} from 'services/location'
// models
import {Address} from 'models'

export const useData = (
  setAddress: (value: Address) => void,
  clean: boolean,
  setIsSuccessReserveAddress: Dispatch<SetStateAction<boolean>>
) => {
  const [selectedAddress, setSelectedAddress] = useState(new Address())
  const [hasNoService, setHasNoService] = useState(false)
  const [isMapSearched, setIsMapSearched] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [searchedAddresses, setSearchedAddresses] = useState<IAddressSearch[]>([])
  const initialValues = {
    searchedText: '',
  }

  const form = useFormik({
    initialValues,
    onSubmit: async (values, {setSubmitting}) => {},
  })

  /* -------------------------------------------------------------------------- */
  /*                                  Services                                  */
  /* -------------------------------------------------------------------------- */

  const getReverseGeoCodingHandler = async ({lat, long}: {lat: string; long: string}) =>
    await services.location.getReverseGeoCoding({
      lat,
      long,
    })

  const searchLocation = async () =>
    await services.location.search({
      term: form.values.searchedText,
    })

  /* -------------------------------------------------------------------------- */
  /*                                   Queries                                  */
  /* -------------------------------------------------------------------------- */

  const {
    isLoading: isLoadingSearch,
    isFetching: isFetchingSearch,
    refetch: search,
  } = useQuery(['search-location', form.values.searchedText], searchLocation, {
    retry: false,
    enabled: !!form.values.searchedText,
    onSuccess: async (data) => {
      const tempSearchedAddresses = data.items
      setSearchedAddresses(tempSearchedAddresses)
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                                  Mutations                                 */
  /* -------------------------------------------------------------------------- */

  const {
    isLoading: isLoadingReserve,
    isSuccess,
    mutate: mutateReserve,
  } = useMutation(['reserve-geo-coding-with-lat-long'], getReverseGeoCodingHandler, {
    onSuccess: async (data) => {
      const addressReverseData = new Address(data)
      if (!addressReverseData.getHasDelivery()) {
        setHasNoService(true)
      } else if (!!addressReverseData.getAddress()) {
        setAddress(addressReverseData)
        setSelectedAddress(addressReverseData)
      }
      setIsSubmitted(true)
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                                  Handlers                                  */
  /* -------------------------------------------------------------------------- */

  const handleClose = () => {
    setHasNoService(false)
    setIsSubmitted(false)
    setAddress(new Address())
    setSelectedAddress(new Address())
  }

  const onReserve = async ({lat, long}: {lat: string; long: string}) =>
    await mutateReserve({lat, long})

  /* -------------------------------------------------------------------------- */
  /*                                 LifeCycles                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (clean) {
      setIsSubmitted(false)
    }
  }, [clean])

  useEffect(() => {
    setIsSuccessReserveAddress(isSuccess)
  }, [isSuccess])

  return {
    hasNoService,
    handleClose,
    isLoadingReserve,
    onReserve,
    form,
    isLoadingSearch: isLoadingSearch || isFetchingSearch,
    submitSearch: search,
    searchedAddresses,
    isMapSearched,
    setIsMapSearched,
    isSubmitted,
    setIsSubmitted,
    selectedAddress,
  }
}
